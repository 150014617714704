import { FunctionalComponent, h } from 'preact'
import '../../style/pages/home/reset.scss'
import '../../style/pages/home/index.scss'
import config from '../../config/config'

const Home: FunctionalComponent = () => {
  return (
    <div class="wrap">
      <section id="header">
        <img src="/assets/images/home/header.png" class="lp_img" />

        <div class="app_store">
          <a href={config.APPLE_STORE} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/home/btn_appstore.png" alt="appストアアイコン" />
          </a>
        </div>

        <div class="play_store">
          <a href={config.PLAY_STORE} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/home/btn_playstore.png" alt="googleplayストアアイコン" />
          </a>
        </div>
      </section>

      <section>
        <img src="/assets/images/home/title_01.png" class="lp_img" />
        <img src="/assets/images/home/tokuchou_01.png" class="lp_img" />
        <img src="/assets/images/home/tokuchou_02.png" class="lp_img" />
        <img src="/assets/images/home/tokuchou_03.png" class="lp_img" />
      </section>

      <section>
        <img src="/assets/images/home/original_01.png" class="lp_img" />
        <img src="/assets/images/home/title_02.png" class="lp_img" />
        <img src="/assets/images/home/original_02.png" class="lp_img" />
        <img src="/assets/images/home/original_03.png" class="lp_img" />
      </section>

      <section>
        <img src="/assets/images/home/title_03.png" class="lp_img" />
        <img src="/assets/images/home/koukokukeisai_01.png" class="lp_img" />
        <img src="/assets/images/home/koukokukeisai_02.png" class="lp_img" />
        <img src="/assets/images/home/koukokukeisai_03.png" class="lp_img" />
        <img src="/assets/images/home/title_04.png" class="lp_img" />
        <img src="/assets/images/home/koukokukeisai_04.png" class="lp_img" />
      </section>

      <section>
        <img src="/assets/images/home/footer.png" class="lp_img" />
      </section>

      <section id="footer">
        <div class="inner">
          <ul class="flexbox link">
            <li>
              <a href="mailto:support@mangatote.jp">お問い合わせ</a>
            </li>
            <li>
              <a href="/home/privacypolicy">プライバシーポリシー</a>
            </li>
            <li>
              <a href="https://amazia.co.jp/" target="_blank" rel="noopener noreferrer">
                運営会社
              </a>
            </li>
          </ul>
          <p class="copyright">©2022 Amazia, Inc. All Rights Reserved.</p>
        </div>
      </section>
    </div>
  )
}

export default Home
