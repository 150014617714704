import palfeLocalConfig from './config.local'

const supportEmail = (): string => {
  if ('SUPPORT_EMAIL' in palfeLocalConfig) {
    return palfeLocalConfig.SUPPORT_EMAIL
  }
  return 'support@mangatote.jp'
}

const devApiHost = (): string => {
  if ('API_HOST' in palfeLocalConfig) {
    return palfeLocalConfig.API_HOST
  }
  return 'https://dev-api.amazia.biz'
}

const apiHost = (): string => {
  const host = location.host
  switch (true) {
    case /^palfe.jp$/.test(host):
      return 'https://api.palfe.jp'
    case /^st-web.amazia.biz$/.test(host):
      return 'https://st-api2.amazia.biz'
    default:
      return devApiHost()
  }
}

const googleAdClient = (): string => {
  if ('GOOGLE_AD_CLIENT' in palfeLocalConfig) {
    return palfeLocalConfig.GOOGLE_AD_CLIENT
  }
  return 'ca-pub-6350063193447477'
}

const googleAdSlot = (): string => {
  if ('GOOGLE_AD_SLOT' in palfeLocalConfig) {
    return palfeLocalConfig.GOOGLE_AD_SLOT
  }
  return '6621643008'
}

const appleStoreUri = (): string => {
  return 'https://apps.apple.com/jp/app/palfe-%E3%83%91%E3%83%AB%E3%83%95%E3%82%A7-%E5%A5%B3%E5%AD%90%E3%81%8C%E6%A5%BD%E3%81%97%E3%82%80%E3%83%9E%E3%83%B3%E3%82%AC-%E3%82%A8%E3%83%B3%E3%82%BF%E3%83%A1%E6%83%85%E5%A0%B1%E3%82%A2%E3%83%97%E3%83%AA/id1490515327'
}

const playStoreUri = (): string => {
  return 'https://play.google.com/store/apps/details?id=jp.palfe'
}

const palfeConfig = {
  API_HOST: apiHost(),
  SUPPORT_EMAIL: supportEmail(),
  GOOGLE_AD_CLIENT: googleAdClient(),
  GOOGLE_AD_SLOT: googleAdSlot(),
  APPLE_STORE: appleStoreUri(),
  PLAY_STORE: playStoreUri()
}

export default palfeConfig
